"use client"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import React, { useEffect } from "react"
import { useAccount } from "wagmi"
import { IS_PROD } from "../../IS_PROD"

export const VaultContext = React.createContext<
  | {
      onBehalfOf: string | null
      setOnBehalfOf: (vault: `0x${string}` | null) => void
    }
  | undefined
>(undefined)

export const VaultProvider = ({ children }: { children: React.ReactNode }) => {
  // TODO: localstorage this
  const [onBehalfOf, setOnBehalfOf] = React.useState<`0x${string}` | null>(null)
  const { address } = useAccount()

  useEffect(() => {
    setOnBehalfOf(null)
  }, [address])
  return (
    <VaultContext.Provider value={{ onBehalfOf, setOnBehalfOf }}>
      {children}
    </VaultContext.Provider>
  )
}

export const useVaults = (getVaultsFromAPI?: boolean) => {
  const context = React.useContext(VaultContext)

  if (context === undefined) {
    throw new Error("useVault must be used within a VaultProvider")
  }
  const { address } = useAccount()
  // TODO: use user chain instead of hardcode
  const chain = IS_PROD ? "mainnet" : "sepolia"
  const query = useQuery<{
    asDelegate: {
      chain: number
      delegate: `0x${string}`
      vault: `0x${string}`
      permissionIds: number[]
      source: "MPERM"
    }[]
  }>({
    queryKey: ["modular-perms", address],
    queryFn: async () => {
      const response = await axios.get(
        `/api/blessnet/permissions?chain=${chain}&address=${address}`,
      )
      return response.data
    },
    enabled: Boolean(address) && getVaultsFromAPI,
  })
  return { ...context, ...query }
}
