import deployments from "./contracts.json"
import type { Contracts } from "./types"

export const config = {
  ...deployments,
} as unknown as {
  [key: string]: { contracts: Contracts }
}

// eslint-disable-next-line turbo/no-undeclared-env-vars
export const IS_PROD = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === "main"
