/* eslint-disable quote-props */
import { Chain } from "@rainbow-me/rainbowkit"

export const blessnetSepolia: Chain = {
  id: 11145513,
  iconUrl: "/__blessnet-logo.png",
  name: "Blessnet Sepolia",
  nativeCurrency: {
    name: "Bless Token",
    symbol: "BLESS",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://blessnet-sepolia-testnet.rpc.caldera.xyz/http"],
      webSocket: ["wss://blessnet-sepolia-testnet.rpc.caldera.xyz/ws"],
    },
  },
  blockExplorers: {
    default: {
      name: "Blessnet Sepolia Explorer",
      url: "https://blessnet-sepolia-testnet.explorer.caldera.xyz/",
    },
  },
  testnet: true,
}

export const blessnet: Chain = {
  id: 45513,
  iconUrl: "/__blessnet-logo.png",
  name: "Blessnet",
  nativeCurrency: {
    name: "Bless Token",
    symbol: "BLESS",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://blessnet.calderachain.xyz/http"],
      webSocket: ["wss://blessnet.calderachain.xyz/ws"],
    },
  },
  blockExplorers: {
    default: {
      name: "Blessnet Explorer",
      url: "https://blessnet.calderaexplorer.xyz/",
    },
  },
  testnet: true,
}
